import planDescriptions from "./plans";

const userStorageKey = "user";

export const sleep = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
};

export const getIdByKey = (key: string): string => {
  const plan = Object.values(planDescriptions).find((plan: PlanDetails) => {
    return key === plan.key;
  }) as PlanDetails;
  return plan ? plan.id : null;
};

export const getProductByKey = (key: string): PlanDetails => {
  return Object.values(planDescriptions).find((plan: PlanDetails) => {
    return key === plan.key;
  }) as PlanDetails;
};

export const getUser = (): User => {
  const user = sessionStorage.getItem(userStorageKey);
  return JSON.parse(user);
};

export const loadUser = (user): void => {
  if (!user) {
    throw new Error("Expected user to be object, but received " + user);
  }
  sessionStorage.setItem(userStorageKey, JSON.stringify(user));
};

export const unloadUser = (): void => {
  sessionStorage.removeItem(userStorageKey);
};

export const getFormattedDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  return Intl.DateTimeFormat("en-us", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  }).format(date);
};

export const getFormattedPrice = (price: number, currency: string = "USD"): string =>
  Intl.NumberFormat("en-us", {
    style: "currency",
    currency,
  }).format(price);

export const getRecoveryURL = (domain: string, orderId: string): string => {
  const payload = {
    recovery_expiration: new Date().getTime() + 60_000,
    order_id: orderId,
  };
  const orderPayload = btoa(JSON.stringify(payload));
  return `https://${domain}/?intent=recover&order=${orderPayload}`;
};

// @ts-ignore
export const hasBrowserSkusService = () => !!(navigator.brave && window.chrome.braveSkus);

export const isLinux = (): boolean => !/Android/.test(navigator.userAgent) && /Linux|Ubuntu/.test(navigator.userAgent);

export const propagateSafeParams = (
  from: string | URL,
  to: string | URL,
  { allowAll }: { allowAll: boolean } = { allowAll: false }
): URL => {
  const safeParams = ["ux"];

  let newTo: URL;
  try {
    from = new URL(from);
    newTo = new URL(to, from?.origin || location.origin);

    for (const [k, v] of from.searchParams) {
      if (allowAll || safeParams.includes(k)) {
        newTo.searchParams.append(k, v);
      }
    }
  } catch (e) {
    console.error(e);
  }

  return newTo;
};
