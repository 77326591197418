import { browser } from "$app/environment";
import { BRAVE_TALK_ID, BRAVE_SEARCH_ID, BRAVE_VPN_ID, BRAVE_LEO_ID } from "$lib/Env";

const checkBrowserSupport = (unsupportedMessage, fn) => {
  if (!browser) return;

  let isSupported = true;
  let message;

  if (fn && !fn()) {
    isSupported = false;
    message = unsupportedMessage;
  }

  return {
    isSupported,
    message,
  };
};

let plans = {
  [BRAVE_TALK_ID]: {
    id: BRAVE_TALK_ID,
    key: "talk",
    name: "Brave Talk Premium",
    baseName: "Brave Talk",
    description:
      "Brave Talk brings unlimited, private one-on-one video calls, right to your browser. With Brave Talk Premium, you can host calls with hundreds of people, and use added features like:",
    link: "https://brave.com/talk/",
    price: 7,
    gradient: "#gradient-06",
    icon: "brave-talk-icon.svg",
    features: ["Call recordings", "Participant muting & passcodes", "Groupwatch for YouTube", "Cancel any time"],
    freeVersion: "Brave Talk free access (1-1 calls)",
    actionPhrase: "start a call",
    usesNativeSDK: false,
    hasTrial: true,
    trialDays: 7,
    browserSupport: checkBrowserSupport(),
  },
};

if (BRAVE_SEARCH_ID) {
  plans = {
    ...plans,
    [BRAVE_SEARCH_ID]: {
      id: BRAVE_SEARCH_ID,
      key: "search",
      name: "Brave Search Premium",
      baseName: "Brave Search",
      description:
        "Brave Search doesn't profile you, and it's not beholden to Big Tech. Help support the Brave Search mission and see search results ad-free.",
      link: "#",
      price: 3,
      gradient: "#gradient-03",
      icon: "brave-search-icon.svg",
      features: [
        "Support private, independent search",
        "See search results ad-free",
        "Get a cleaner view on all results pages",
        "Cancel any time",
      ],
      freeVersion: "Brave Search free access",
      actionPhrase: "search the web",
      usesNativeSDK: false,
      hasTrial: false,
      browserSupport: checkBrowserSupport(),
    },
  };
}

if (BRAVE_VPN_ID) {
  plans = {
    ...plans,
    [BRAVE_VPN_ID]: {
      id: BRAVE_VPN_ID,
      key: "vpn",
      name: "Brave VPN",
      baseName: "Brave VPN",
      description:
        "Traveling? On public Wi-Fi? Don't trust your ISP? It's time for the whole-device protection of Brave Firewall + VPN.",
      link: "#",
      price: 9.99,
      gradient: "#gradient-05",
      icon: "brave-vpn-icon.svg",
      features: [
        "Block trackers on any app",
        "Protect up to 5 devices",
        "Unlimited with speeds up to 500mbps",
        "Desktop, Android, & iOS",
        "Cancel any time",
      ],
      freeVersion: "No VPN",
      actionPhrase: "subscribe",
      usesNativeSDK: true,
      hasTrial: true,
      trialDays: 7,
      weight: 2,
      browserSupport: checkBrowserSupport(
        "Brave VPN is currently supported on Android, iOS, macOS, and Windows devices. Support for Linux is coming soon.",
        () =>
          navigator.brave?.isBrave &&
          !(!/Android/.test(navigator.userAgent) && /Linux|Ubuntu/.test(navigator.userAgent)) &&
          !!(navigator.brave && window.chrome.braveSkus)
      ),
    },
  };
}

if (BRAVE_LEO_ID) {
  plans = {
    ...plans,
    [BRAVE_LEO_ID]: {
      id: BRAVE_LEO_ID,
      key: "leo",
      name: "Leo Premium",
      baseName: "Leo",
      description:
        "Leo is an AI-powered intelligent assistant, built right into the browser. Leo can answer questions, help accomplish tasks, and more. Leo Premium uses more models to handle more requests each day, and lets you try new features first.",
      link: "#",
      price: 14.99,
      gradient: "#gradient-03",
      icon: "brave-leo-icon.svg",
      features: [
        "Access to industry-leading LLMs",
        "Higher rate limits",
        "Early access to new features",
        "Cancel any time",
      ],
      freeVersion: "Leo free access",
      actionPhrase: "subscribe",
      usesNativeSDK: true,
      hasTrial: true,
      trialDays: 7,
      weight: 1,
      browserSupport: checkBrowserSupport(
        "Leo is only accessible in the Brave browser, and it looks like you're not using Brave. Please download Brave to continue, or contact Brave support.",
        () => {
          return navigator.brave?.isBrave && !!(navigator.brave && window.chrome.braveSkus);
        }
      ),
    },
  };
}

export default plans;
